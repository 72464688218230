import { FC } from 'react';
import PNG_Frame1 from '../../assets/svg/gallery/gal_1.png';
import PNG_Frame2 from '../../assets/svg/gallery/gal_2.png';
import PNG_Frame3 from '../../assets/svg/gallery/gal_3.png';
import PNG_Frame4 from '../../assets/svg/gallery/gal_4.png';
import PNG_Frame5 from '../../assets/svg/gallery/gal_5.png';
import PNG_Brush1 from '../../assets/svg/gallery/gal_brush.png';
import PNG_Brush2 from '../../assets/svg/gallery/gal_brush2.png';
import { ReactComponent as GalleryTextSVG } from '../../assets/svg/gallery/gallery_text.svg';
import { ReactComponent as OpenSeaSVG } from '../../assets/svg/gallery/opensea.svg';
import { ReactComponent as VrGallerySVG } from '../../assets/svg/gallery/vr-gallery.svg';

interface GallerySectionProps {
  sectionRef?: any;
}

const VRGalleryExternalURL =
  'https://elderly-paste-700.notion.site/VR-GALLERY-fb520079861d4b1795d679c382d4e252';

const OpenSeaExternalURL = 'https://opensea.io/collection/heroduck';

export const GallerySection: FC<GallerySectionProps> = ({ sectionRef }) => {
  return (
    <div ref={sectionRef}>
      <div className="hidden overflow-hidden md:block">
        <div className="relative flex h-[840px] flex-col justify-end bg-brand-1">
          <GalleryTextSVG className="absolute top-12 left-1/2 z-30 ml-8 -translate-x-1/2 transform" />

          <div className="absolute top-20 left-1/2 z-10 flex w-full max-w-[90rem] -translate-x-1/2 transform items-start justify-around space-x-[22rem] px-20">
            <img src={PNG_Frame2} alt="" className="ml-16" />
            <img src={PNG_Frame4} alt="" className="mt-3" />
          </div>

          <img
            src={PNG_Brush1}
            alt=""
            className="absolute top-36 -left-24 w-56 "
          />
          <img
            src={PNG_Brush2}
            alt=""
            className="absolute top-72 -right-12 w-36 "
          />

          <div className="absolute top-44 left-1/2 z-10 flex w-full max-w-[115rem] -translate-x-1/2 transform items-end justify-around">
            <img src={PNG_Frame1} alt="" className="mb-16" />
            <img src={PNG_Frame3} alt="" />
            <img src={PNG_Frame5} alt="" className="mb-20" />
          </div>

          <div className="relative flex h-40 flex-col justify-center">
            <div className="mx-auto flex w-full max-w-[100rem] items-center justify-between px-16">
              <div className="wh-2 rotate-45 transform bg-black" />
              <div className="h-0.5 w-full bg-black" />
              <div className="wh-2 rotate-45 transform bg-black" />
            </div>

            <div className="absolute top-[40%] left-1/2 mx-auto flex w-96 -translate-x-1/2 -translate-y-1/2 transform items-end space-x-6 bg-brand-1 px-10">
              <OpenSeaSVG
                onClick={() => window.open(OpenSeaExternalURL, '_blank')}
                className="cursor-pointer pb-4"
              />
              <VrGallerySVG
                onClick={() => {
                  window.open(VRGalleryExternalURL, '_blank');
                }}
                className="cursor-pointer pt-4"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block overflow-hidden md:hidden">
        <div className="relative h-[780px] bg-brand-1">
          <div className="relative mx-auto max-w-md">
            <GalleryTextSVG className="absolute top-3 left-6 w-40" />
            <img
              src={PNG_Frame3}
              alt=""
              className="absolute -right-2 top-6 w-56"
            />
            <img
              src={PNG_Brush1}
              alt=""
              className="absolute top-28 left-16 w-24 "
            />
            <img
              src={PNG_Frame1}
              alt=""
              className="absolute -left-12 top-40 z-20 w-60"
            />
            <img
              src={PNG_Brush2}
              alt=""
              className="absolute top-64 -right-3 w-16 "
            />
            <img
              src={PNG_Frame2}
              alt=""
              className="absolute top-[16.5rem] left-1/2 z-10 w-48 -translate-x-1/2 transform"
            />
            <img
              src={PNG_Frame5}
              alt=""
              className="absolute -right-10 top-96 z-20 w-56"
            />
            <img
              src={PNG_Frame4}
              alt=""
              className="absolute -left-6 top-96 z-[1] w-56"
            />
          </div>

          <div className="absolute bottom-40 right-0 flex items-center justify-end">
            <div className="wh-2 rotate-45 transform bg-black" />
            <div className="h-0.5 w-32 bg-black" />
          </div>

          <div className="absolute bottom-0 left-5 flex flex-row pb-5">
            <OpenSeaSVG
              onClick={() => window.open(OpenSeaExternalURL, '_blank')}
              className="w-1/2"
            />
            <VrGallerySVG
              onClick={() => {
                window.open(VRGalleryExternalURL, '_blank');
              }}
              className="mt-10 w-1/2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
